.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: url('./assets/jag.jpg');
  background-size: cover;
}

.logo {
  margin-top: 100px;
}

.logo p { 
  margin-top: 10px;
  font-size: 16px;
  color: #31475e;
  text-transform: uppercase;
  text-align: center;
}